/* toastify css*/
@import 'react-toastify/dist/ReactToastify.css';
/* skeleton css */
@import 'react-loading-skeleton/dist/skeleton.css';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  font-family: 'Pretendard Variable', 'Noto Sans KR', -apple-system, sans-serif;
}

#__next {
  width: 100%;
  height: 100%;
}

a {
  color: inherit;
  text-decoration: none;
}

:root {
  --toastify-toast-min-height: 40px;
  --toastify-toast-max-height: 800px;
}
